import * as React from 'react';

class App extends React.Component {
  render() {

    var colStyle = {
      color: '#f28c83'

    };

    return (
      <div>
        <div id="main-nav" className="navbar navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#site-nav">
                <span className="icon-bar" /><span className="icon-bar" /><span className="icon-bar" />
              </button>
              <a className="navbar-brand scrollto" href="#home">
                <img src={process.env.PUBLIC_URL + '/Content/images/logo.png'} alt="" />
              </a>
            </div>
            <div id="site-nav" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
              <li class="sr-only">
                        <a href="#home" class="scrollto">Home</a>
                    </li>
                    <li>
                        <a href="#services" class="scrollto">My Classes</a>
                    </li>
                    <li>
                        <a href="#about" class="scrollto">About Me</a>
                    </li>                   
                    <li>
                        <a href="#contact" class="scrollto">Contact Me</a>
                    </li>
                    <li>
                        <a href="#faq" class="scrollto">FAQ's</a>
                    </li>
              </ul>
            </div>
          </div>
        </div>
        <header id="home">

          <div className="container text-center">
            <p style={colStyle}>Welcome to</p>
            <h1 style={colStyle}>Yoga by Gemma</h1>            
          </div>
        </header>

        <section id="services" className="add-padding">
          <div className="container">
            <div className="row">
              <div class="col-sm-4 service-item color1 scrollimation scale-in d1">
                <div class="service-icon"><i class="fa fa-users "></i></div>
                <h3>Group Classes</h3>
                <p>I teach in Honor Oak. <a href="#portfolio" class="scrollto">more details</a></p>
              </div>
              <div class="col-sm-4 service-item color3 scrollimation scale-in">
                <div class="service-icon"><i class="fa fa-user"></i></div>
                <h3>Private & Corporate Classes</h3>
                <p>A personal yoga journey devised specifically for your needs in the comfort of your own home or office</p>
                <a href="#privateDetails" class="scrollto">more details</a>
              </div>
              <div class="col-sm-4 service-item color2 scrollimation scale-in d2">
                <div class="service-icon"><i class="fa fa-female"></i></div>
                <h3>Pre & Post Natal Yoga</h3>
                <p>I can teach you privately or as a small group. I will  tailor these classes to your specific needs. <a href="#prepostDetails" class="scrollto">more details</a></p>
            </div>
            </div>
          </div>
        </section>

        <section id="portfolio" class="add-padding">

      <div class="container">
          <div class="row">
              <div class="col-sm-8 scrollimation fade-right d1">
                  <h3>Group Classes - Honor Oak</h3>
                  <h4 class="text-muted">Ackroyd Community Center</h4>
                  <p>Vinyassa flow class every Monday 19:45-21:00.</p>
                  <p>Suitable for all abilities.</p>
                  <p>£10 Drop in</p>
                  <p>
                      In this class you can expect to move freely, feel good and most definitely have fun.
                      Each class  is designed to bring about awareness to your own body - always aiming to balance
                      the body and the mind whilst moving to the breath.
                  </p>
              </div>

              <div class="col-sm-4 scrollimation fade-right d1">
                  <section id="map">
                      <div id="gmapHonorOak"></div>
                  </section>
              </div>
          </div>
      </div>

    <section id="prepostDetails">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 color2 scrollimation scale-in d3">
                    <h3>Pre & Post Natal Yoga Class Details</h3>
                    <p>
                        Each class uses a wide range of modified postures with the aim of supporting and strengthening a woman's experience of both pregnancy and birth.
                        The class welcomes those who are completely new to yoga and those who already have a regular practice. Please come after 12 weeks. The class entails postures,
                        supported posture work [using walls, chairs etc.], some restorative postures, pranayama [breathing practice and meditation].
                        The ultimate aim is to empower women in their beautiful journey of becoming a mother.
                        I have been trained by the wonderul Uma Dinsmore Tuli, Lisa White and Emma Yate.

                    </p>
                    <p>For private classes please email me using the <a href="#contact" class="scrollto">Contact Me</a> form.</p>
                </div>
            </div>
        </div>
    </section>

    <section id="privateDetails">
        <div class="container">
            <div class="row color3">
                <div class="col-sm-6 color2 scrollimation scale-in d3">
                    <h3>Private Class Details</h3>
                    <p>
                        This will be a bespoke class specifically designed for the student's needs. For beginners it will offer you an opportunity to explore
                        different yoga poses in a private environment, enabling you to grow in confidence for your own practice.

                        For those who already have a yoga foundation, the class will offer you the opportunity to explore poses more deeply, practice breathing and meditation
                        techniques and receive focused attention to meet your needs.

                        How to book: Please use the <a href="#contact" class="scrollto">Contact Me</a> form. Classes can be held at your home or place of work.
                        Also happy for you to share a class with a partner, friend or family member.
                    </p>
                </div>
                <div class="col-sm-6 color3 scrollimation scale-in d3">
                    <h3>Corporate Class Details</h3>
                    <p>
                        Classes are designed to address the aches, pains, stresses and strains of the corporate world - bringing about a healthier body and mind.
                        Classes can be held in a meeting room and can be offered during lunch or outside of working hours. Yoga mats can be supplied. For prices, please use the <a href="#contact" class="scrollto">Contact Me</a>
                        form for more information.
                    </p>
                </div>
            </div>
        </div>
    </section>
    </section>
      <section id="about" class="add-padding bg-color2">

    <div class="container">
        <div class="row">
            <div class="col-sm-6 scrollimation fade-right d1">
                <img class="img-responsive polaroid" src="/Content/images/about.jpg" alt="" />
            </div>
            <div class="col-sm-6 scrollimation fade-left d3">
                <p class="lead">
                    I began my yoga practice in 1999. A seed was planted and a growing passion sewn. It revolutionised the way I thought about exercise and health.
                    I became a certified teacher in May 2013.
                </p>
                <p>
                    Before I trained I thought I had to master wall-free head stands and seamless full splits.
                    Eventually I got over that and did the training anyway. I felt relieved to see my fellow students and teachers also have limitations of some sort.
                    No matter how long we practice there will still be room for refinement. I don't strive for perfection. Instead I like to celebrate little victories and be kind to myself on the
                    days that I can't balance in crow pose!
                </p>
                <p>I teach because it makes me a better student. I teach a vinyasa flow style, helping students to find space in their own bodies with a calm, focused mind. I like working out modifications with students who enter the class with their well-used bodies. I like to play music and have fun. I like to play around with challenging arm balances and laugh at myself when the balance beats me.</p>

                <p>In 2011 and again in 2013 I became a mother to two beautiful boys. They have completely rocked my world. During my first pregnancy I became acutely aware about the importance of keeping healthy and found yoga to be the perfect support through pregnancy and motherhood.</p>

                <p>So that’s me. I look forward to meeting you on your mat.</p>
            </div>
        </div>
    </div>
</section>

        <section id="contact" className="add-padding">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-6 col-md-5 text-right scrollimation fade-up d1">
                <h1 className="big-text">Contact Me</h1>
                <p className="lead">If you have any questions about my
                oils or just want to make a general enquiry then get in touch!</p>               
                <a href="#contact" className="scrollto">Contact Me</a>             
                <ul class="social-links">
                    <li><a href="https://twitter.com/yogabygemma"><i class="fa fa-twitter fa-fw"></i></a></li>
                    <li><a href="https://www.facebook.com/YogaByGemma"><i class="fa fa-facebook fa-fw"></i></a></li>
                </ul>
                 </div>
              <form method="post" className="col-sm-6 col-md-offset-1 scrollimation fade-left d3" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />

                <div className="form-group">
                  <label className="control-label">Name</label>
                  <div className="controls">
                    <input
                      id="contact-name"
                      name="name"
                      placeholder="Your name"
                      className="form-control requiredField"
                      data-new-placeholder="Your name"
                      type="text"
                      data-error-empty="Please enter your name"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">Email</label>
                  <div className="controls">
                    <input
                      id="contact-email"
                      name="email"
                      placeholder="Your email"
                      className="form-control requiredField"
                      data-new-placeholder="Your email"
                      type="text"
                      data-error-empty="Please enter your email"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">Message</label>
                  <div className="controls">
                    <textarea
                      id="contact-message"
                      name="message"
                      placeholder="Your message"
                      className="form-control requiredField"
                      data-new-placeholder="Your message"
                      data-error-empty="Please enter your message"
                    />
                  </div>
                </div>
                <p>
                  <button
                    type="submit"
                    className="btn btn-color2 btn-block"
                    data-error-message="Error!"
                    data-sending-message="Sending..."
                  >
                    Send
                  </button>
                </p>
              </form>
            </div>
          </div>
        </section>

        <section id="faq" class="add-padding bg-color2">

    <div class="container">

        <div class="row">
            <h3>FAQ's</h3>
            <div class="col-sm-3 scrollimation fade-left d3">
                <h3>I am a beginner. What do I need to know?</h3>
                <p>
                    Yoga is for everyone. It will make you feel great. It will re-energise and relax you. It will make you stronger and more flexible. Building strength and improving flexibility
                    can be hard work. In yoga we teach you to listen to your body. If you feel pain, ease off.
                    Either the pose isn't right for your body in that moment or there is something a-miss in the alignment.
                    Either way when your body tells you to back off... you should do so.
                </p>
            </div>

            <div class="col-sm-3 scrollimation fade-left d3">
                <h3>What should I wear?</h3>
                <p>
                    Regardless of gender, the best clothing to wear to your yoga class is clothes that you are comfortable to move in. Dress in fabric that allows the body to breathe and move freely. The less bulky and bunchy the better.
                    May also be handy to dress in layers as it's nice to keep warm during the relaxation poses.
                </p>
            </div>
            <div class="col-sm-3 scrollimation fade-left d3">
                <h3>Can I eat before class?</h3>
                <p>
                    It is best to practice on an empty stomach. Ideally avoid eating anything upto 2 hours before class. Digestion requires energy and when you practice yoga after eating, energy goes to the muscles your are using rather than digesting your food fully.
                </p>
            </div>
            <div class="col-sm-3 scrollimation fade-left d3">
                <h3>I am pregnant. Can I practice yoga?</h3>
                <p>
                    If you have not practiced yoga before, the general recommendation is to not start during the first 3 months of pregnancy, since your body is not used to it. If you have a regular yoga practice then you may continue.
                </p>
            </div>

        </div>

    </div>

</section>
      </div>
    );
  }
}

export default App;
